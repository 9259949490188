<template>
  <div>
    <validation-observer
      ref="editAccount"
      #default="{invalid}"
    >
      <!-- Media -->
      <b-media
        no-body
        class="mb-2"
      >
        <b-media-aside>
          <b-avatar
            ref="previewEl"
            :src="avatarPreview || currentAvatarUrl"
            :text="avatarText(userData.lastName + ' ' + userData.firstName)"
            variant="light-primary"
            size="80px"
            rounded
          />
        <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ uploading ? 'Ładowanie...' : 'Wgraj zdjęcie' }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="uploadAvatar"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-if="avatarPreview || currentAvatarUrl"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="clearAvatar"
          >
            Usuń zdjęcie
          </b-button>
          <!--/ reset --><br>
          <b-card-text>Możliwość wgrania zdjęcia w formacie JPG, GIF i PNG. Maksymalny rozmiar pliku to 800kB</b-card-text>
        </b-media-body>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="submit">
        <b-row>

          <!-- Field: Status -->
          <template v-if="userData.status === 'INVITED'">
            <b-col
              cols="6"
              md="2"
            >
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="userData.status"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="2"
            >
              <b-button
                variant="outline-secondary"
                size="sm"
                class="mt-2"
                style="height: 37.58px"
                block
                @click="resendInvite"
              >
                Ponów zaproszenie
              </b-button>
            </b-col>
          </template>
          <template v-else>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="userData.status"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
                />
              </b-form-group>
            </b-col>
          </template>

          <!-- Field: lastName -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Nazwisko"
              label-for="lastName"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >
                <b-form-input
                  id="lastName"
                  v-model="userData.lastName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <!-- Field: firstName -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Imię"
              label-for="firstName"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >

                <b-form-input
                  id="firstName"
                  v-model="userData.firstName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{errors}"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  :disabled="!isAdmin"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Dealer -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-if="isAdmin && hasDealerDepartmentFields"
              label="Dealer"
              label-for="dealer"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >
                <v-select
                  v-if="dealerOptions && dealerOptions.length"
                  v-model="dealerAdapter"
                  :options="dealerOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="dealer"
                />
                <small
                  v-else
                  class="text-warning"
                >Pobieranie listy dealerów...</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Department -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-if="hasDealerDepartmentFields"
              label="Dział"
              label-for="department"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >
                <v-select
                  v-model="userData.departments"
                  multiple
                  :options="departmentOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="department"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Role -->
          <b-col
            v-if="isAdmin"
            cols="12"
            md="4"
          >
            <b-form-group
              label="Poziom dostępu"
              label-for="role"
            >
              <validation-provider
                #default="{errors}"
                rules="required"
              >
                <v-select
                  v-model="roleAdapter"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
          :disabled="invalid || loading"
        >
          Zapisz zmiany
        </b-button>
        <b-button
          variant="outline-secondary"
          to="/users/list"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Anuluj
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormFile, BForm, BMediaAside, BMediaBody, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AvatarMixin from '@/mixins/avatarMixin'
import departmentOptions from '@/helpers/departmentOptions'
import roleOptions from '@/helpers/roleOptions'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BMediaBody,
    BMediaAside,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardText,
  },
  mixins: [
    AvatarMixin,
  ],
  props: {
    userData: {
      type: Object,
      required: true,
    },
    hasDealerDepartmentFields: {
      type: Boolean, default: false,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'Aktywny', value: 'ACTIVE' },
        { label: 'Zaproszony', value: 'INVITED' },
        { label: 'Nieaktywny', value: 'INACTIVE' },
      ],
      roleOptions,
      departmentOptions,
      required,
      email,
      loading: false,
    }
  },
  computed: {
    dealerList() {
      return this.$store.state.dealer.list ? this.$store.state.dealer.list['hydra:member'] : []
    },

    dealerOptions() {
      const options = []

      this.dealerList.forEach(dealer => {
        options.push({ label: dealer.name, value: dealer['@id'] })
      })

      return options
    },

    dealerAdapter: {
      get() {
        if (this.userData.dealer) {
          return this.dealerOptions.find(dealer => dealer.value === this.userData.dealer['@id'])
        } return null
      },
      set(value) {
        this.userData.dealer = { '@id': value }
      },
    },

    roleAdapter: {
      get() {
        return this.userData.role || this.userData.roles[0]
      },
      set(value) {
        this.$set(this.userData, 'role', value)
      },
    },
  },
  beforeMount() {
    if (this.isManager) {
      this.setManagerFilters()
    }
  },
  methods: {
    setManagerFilters() {
      this.departmentOptions = this.departmentOptions.filter(option => this.currentUser.departments.includes(option.value))
    },
    submit() {
      this.$refs.editAccount.validate().then(valid => {
        if (valid) {
          this.$emit('submit')
        }
      })
    },
    resendInvite() {
      this.$store
        .dispatch('user/resendInvite', { email: this.userData.email })
        .then(response => {
          if (response && response.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zaproszenie wysłano ponownie',
                icon: 'MailIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się wysłać zaproszenia.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
