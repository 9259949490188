<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="warning"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <transition
      name="fade"
      mode="out-in"
    >
      <user-edit-tab-account
        v-if="userData"
        :user-data="userData"
        :has-dealer-department-fields="hasDealerDepartmentFields"
        @submit="submit"
      />
      <div
        v-else
        class="text-center"
      >
        <b-spinner style="width: 4em; height: 4em;" />
      </div>
    </transition>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    UserEditTabAccount,
  },

  data() {
    return {
      userData: null,
    }
  },
  computed: {
    hasDealerDepartmentFields() {
      return ['ROLE_MANAGER', 'ROLE_EMPLOYEE'].indexOf(this.userData.role || this.userData.roles[0]) !== -1
    },
  },
  beforeMount() {
    this.$store.dispatch('user/fetchUser', { id: this.$router.currentRoute.params.id })
      .then(response => {
        if (response && response.status === 200) {
          this.userData = response.data
        } else {
          let error = response && response.data['hydra:description'] ? response.data['hydra:description'] : null
          switch (error) {
            case 'Not Found':
              error = 'Nie znaleziono pracownika.'
              break
            default:
              error = 'Serwer nie odpowiada.'
              break
          }
          this.$root.$emit(
            'global:error',
            `${error}`,
          )
          this.$router.replace('/users/list')
        }
      })
    if (this.isAdmin) {
      this.$store.dispatch('dealer/fetchList')
    }
  },
  methods: {
    submit() {
      const payload = {
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        email: this.userData.email,
        image: this.userData.image,
        role: this.userData.role || this.userData.roles[0],
        dealer: this.hasDealerDepartmentFields ? this.userData.dealer['@id'] : null,
        departments: this.hasDealerDepartmentFields ? this.userData.departments : [],
        status: this.userData.status,
      }

      this.loading = true
      this.$store
        .dispatch('user/editUser', { id: this.userData.id, payload })
        .then(response => {
          if (response && response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zmieniono dane pracownika',
                icon: 'UserCheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/users/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się zmodyfikować danych pracownika.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response ? response.data['hydra:description'] : null,
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
